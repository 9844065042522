@import "themes/dark-theme.scss";
@import "themes/green-theme.scss";
@import "themes/light-theme.scss";

:root {
  --bt-nav-active: #{$primary-50};
  --bt-primary-100: #{$primary-100};
  --bt-primary: #{$primary-base};
  --bt-primary-75: #{$primary-75};
  --bt-primary-50: #{$primary-50};
  --bt-primary-25: #{$primary-25};
  --bt-primary-10: #{$primary-10};
  --bt-primary-contrast: #{$white};
  --bt-bg-opacity: 1;
  --accent: #2596be;

}

.sidebar-nav-link-item:hover {
  background-color: var(--bt-primary-10);
}

.sidebar {
  --cui-sidebar-bg: #{$navigation-dark-bg};
}

// Google's places autocomplete
.pac-container {
  z-index: 10000 !important;
}

/* Variables */
* {
  --cui-border-color: rgba(var(--bt-disabled-rgb), 0.2);
  --cui-emphasis-color-rgb: var(--bt-primary-test-100-rgb);
  --cui-btn-close-color: var(--bt-primary-test-10) !important;
  --cui-btn-color: var(--bt-primary-test-100) !important;
  --cui-btn-bg: transparent;
  --cui-header-divider-border-color: var(--bt-primary-test-10);
  --cui-body-bg-rgb: var(--bt-bg-10-rgb);
  --cui-body-bg: var(--bt-bg);
  --cui-light-rgb: var(--bt-bg-10-rgb);
  --cui-bg-opacity: var(--bt-bg-opacity);
  --cui-footer-bg: var(--bt-bg);
  --cui-sidebar-bg: #424242;
  --cui-body-color: var(--bt-primary-test);
  --cui-body-color-rgb: var(--bt-primary-test);
  --cui-light: var(--bt-primary-test);
  --cui-dark: var(--bt-primary-test);
  --cui-table-color-state: var(--bt-primary-test);
  --cui-table-hover-color: var(--bt-primary-test);
  --cui-table-color-type: var(--bt-primary-test);
  --cui-table-color: var(--bt-primary-test);
  --cui-form-label-color: var(--bt-primary-test-10);
  --cui-breadcrumb-item-active-color: var(--bt-primary-test-10);
  --cui-heading-color: var(--bt-primary-test-100);
  --cui-modal-bg: var(--bt-bg-2);
  --cui-font-sans-serif: "Roboto", sans-serif;

  --cui-secondary-color: rgba(var(--bt-disabled-rgb), 0.75);
  --cui-tertiary-color: rgba(var(--bt-disabled-rgb), 0.3);
  --cui-tertiary-bg: rgba(var(--bt-disabled-rgb), 0.05);
  --cui-medium-emphasis: rgba(var(--bt-disabled-rgb), 0.6);
  --cui-calendar-cell-hover-bg: rgba(var(--bt-disabled-rgb), 0.1);
  --cui-calendar-cell-today-color: var(--bt-primary-50);

  --cui-btn-hover-color: var(--bt-primary-test) !important;
  --cui-btn-active-color: var(--bt-primary-test) !important;
  --cui-pagination-disabled-border-color: rgba(var(--bt-disabled-rgb), 0.1);
  --cui-pagination-disabled-bg: rgba(var(--bt-disabled-rgb), 0.1);
}

body,
html,
#root {
  min-height: 100vh;
  overflow-x: hidden;
}

/* Backgrounds */
body,
html,
#root,
.bg-light,
.header,
footer.footer,
div.footer {
  background-color: var(--bt-bg-2);
}

.sidebar {
  --cui-sidebar-bg: var(--bt-bg-10);
}

/* BaseInput and Input Styles */
.form-label,
label,
input.form-control,
input.form-control::placeholder,
input.form-control::-webkit-input-placeholder,
input.form-control::-moz-placeholder,
.nav-link,
input.MuiInputBase-input {
  color: var(--bt-primary-test-10);
}

a.dropdown-item {
  cursor: pointer;
}

/* SVG Colors */
td svg.icon:not(a.nav-link) {
  color: var(--bt-primary) !important;
}

a.nav-link,
svg.icon,
button.header svg.iocn {
  color: var(--bt-primary-test-100) !important;
  cursor: pointer;
  border-radius: 100%;
}

.settings-panel-dropdown {
  outline: none !important;
  border: none !important;
}

.settings-panel-dropdown .btn.btn-primary {
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.settings-panel-dropdown .btn.btn-primary::after {
  display: none;
}

.nav-item-header {
  transition: all 0.3s ease;
  border-radius: 100%;
  width: 48px;
  width: 48px;
  max-height: 48px;
  max-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item-header:hover svg {
  border-radius: 100%;
  background-color: rgba(var(--bt-disabled-rgb), 0.1);
  border-radius: 100%;
}

.nav-item-header svg {
  width: 18px !important;
  height: 18px !important;
}

.nav-item-header:hover {
  background-color: rgba(var(--bt-disabled-rgb), 0.1);
  box-shadow: 0 0 0 2px rgba(var(--bt-disabled-rgb), 0.1);
}

.nav-item-header:hover svg {
  color: var(--bt-primary-50);
}

.nav-item-dropdown * {
  color: rgb(var(--bt-disabled-rgb));
}

.nav-item-dropdown:hover {
  background-color: rgba(var(--bt-disabled-rgb), 0.1);
}

svg.icon.icon-lg {
  position: relative;

  &:hover {
    color: var(--bt-primary-50) !important;
    border-radius: 50%;
    background-color: rgba(var(--bt-disabled-rgb), 0.1) !important;
    box-shadow: 0 0 0 0.5rem rgba(var(--bt-disabled-rgb), 0.1);
  }

  &:active {
    background-color: rgba(var(--bt-disabled-rgb), 0.2) !important;
    box-shadow: 0 0 0 0.5rem rgba(var(--bt-disabled-rgb), 0.2);
  }
}

/* Input Field Styles */
input.form-control:disabled {
  background-color: transparent;
  outline: none;
  border: none;
}

input.form-control.date-picker-input,
input.form-control.date-picker-input:hover,
.MuiInputBase-root:focus,
.MuiInputBase-root:active,
.MuiInputBase-root:hover,
.MuiInputBase-root {
  border: 1px ridge rgba(var(--bt-disabled-rgb), 0.1) !important;
  outline: none !important;
  box-shadow: none !important;
}

.picker-input-group input {
  padding: 10px;
  margin-left: -10px;
}

.picker-input-group .input-group-text {
  margin-right: -10px;
}

/* Hover Styles */
.sidebar-nav-link-item:hover {
  background-color: var(--bt-primary-10);
}

.sidebar-nav-link-item-logout {
  color: #{$danger-dark} !important;
}

.sidebar-nav-link-item-logout:hover {
  background-color: #{$danger-25} !important;
}

.sidebar-nav-link-item-logout:hover * {
  color: #{$danger-dark} !important;
}

input {
  width: "100%";
}

.sticky-right {
  position: sticky;
  right: 0;
  background-color: white;
  z-index: 1;
}

/* Custom File Field */
.custom-file-input {
  display: block;
  background-color: var(--cui-table-bg);
  cursor: pointer;
  border: 1px solid rgba(var(--bt-disabled-rgb), 0.2);
  border-radius: 4px;
}

li.page-link {
  color: var(--bt-primary-test-100) !important;
  cursor: pointer !important;
}

li.page-item.disabled {
  background-color: rgba(var(--bt-disabled-rgb), 0.1) !important;
}

span {
  color: var(--bt-primary-test);
}

a.sidebar-nav-link-item *,
div.sidebar-brand * {
  color: var(--bt-disabled);
  opacity: 1;
}

a.sidebar-nav-link-item.active * {
  color: var(--bt-primary-50);
  fill: var(--bt-primary-50);
}

a.page-link {
  cursor: pointer !important;
}

span.page-link {
  background-color: var(--bt-primary-100) !important;
}

button.btn-primary {
  background-color: var(--bt-primary-100) !important;
  color: var(--bt-primary-contrast) !important;
}

ol.breadcrumb li.breadcrumb-item::before {
  float: unset !important;
  padding-left: var(--cui-breadcrumb-item-padding-x) !important;
  padding-right: var(--cui-breadcrumb-item-padding-x) !important;
}

.app-top-header {
  background-color: rgba(0, 0, 0, 0.2);
  width: calc(100% + 10rem);
  margin-left: -4rem;
  padding-left: 3rem;
}

.app-sub-header {
  margin-left: -5.25rem;
}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: var(--bt-primary-100) !important;
}



// Apply specific RTL styles.
// The .rtl class is added to the body tag programatically in src/App.tsx.
.rtl {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div,
  li,
  ul,
  ol,
  button,
  table,
  td,
  th,
  thead,
  tbody,
  form,
  label,
  legend,
  fieldset,
  textarea,
  select,
  option,
  pre,
  code,
  blockquote,
  dl,
  dt,
  dd,
  hr,
  figure,
  figcaption,
  main,
  nav,
  section,
  article,
  aside,
  header,
  footer,
  canvas,
  iframe,
  video,
  audio,
  embed,
  object,
  applet,
  frameset,
  frame,
  iframe,
  input,
  button,
  select,
  textarea {
    text-align: right;
    direction: rtl;
  }

  .modal-header h5{
    margin-right: auto;
    font-weight: bold;
  }

  button.btn.btn-close[aria-label="Close"] {
    position: absolute;
    left: 35px;
  }

  @media screen and (min-width: 768px) {
    .adjust-to-sidebar-narrow {
      margin-left: unset;
      margin-right: 4rem;
    }

    .adjust-to-sidebar-wide {
      margin-left: unset;
      margin-right: 15rem;
    }

    #children-root {
      transition: margin 0.3s ease;
      margin-right: 4rem;
    }

    .app-top-header {
      margin-right: -4rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }

    ol.breadcrumb {
      padding: 0 4rem;
    }

    div.footer {
      padding: 1.175rem;
    }
  }
}

body.rtl.sidebar-is-expanded {
  @media screen and (min-width: 768px) {
    #children-root {
      transition: margin 0.3s ease;
      margin-right: 15rem;
    }

    .app-top-header {
      margin-right: 7rem;
    }

    ol.breadcrumb {
      padding: 0 15rem;
    }
  }
}

.form-control {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.5rem;
  min-height: 35px;
  padding: 0 10px 0 10px;
}

.form-control .date-picker-input-group {
  padding: 10px;
}

.form-control > .form-control {
  border: none;
  padding: 0;
}

.header-divider {
  width: calc(100% + 50%);
  margin-right: -4rem;
  margin-left: -4rem;
}

div.MuiAutocomplete-root * {
  border: none !important;
  outline: none !important;
}

div.MuiAutocomplete-root *:hover {
  border: none !important;
  outline: none !important;
}

div.MuiAutocomplete-root *:is(input) {
  border-bottom: 1px solid rgba(var(--bt-disabled-rgb), 0.2) !important;
}

div.MuiAutocomplete-root *:is(input:hover) {
  border-bottom: 1px solid rgba(var(--bt-disabled-rgb), 0.4) !important;
}

div.MuiAutocomplete-root {
  // border: 1px ridge rgba(var(--bt-disabled-rgb), 0.2) !important;
  // border-radius: 4px !important;
  outline: none !important;
  box-shadow: none !important;
}

div.MuiAutocomplete-root:hover,
div.MuiAutocomplete-root:active,
div.MuiAutocomplete-root:focus {
  // border: 1px ridge rgba(var(--bt-disabled-rgb), 0.4) !important;
  // border-radius: 4px !important;
  outline: none !important;
  box-shadow: none !important;
}

.modal-header {
  border: none;

  h5 {
    margin-left: auto;
    font-weight: bold;
  }
}
