/* Light Theme */
.light-theme * {
  --bt-disabled: #{$gray-100};
  --bt-disabled-rgb: 0, 0, 0;
  --bt-primary-test: #{$secondary-dark};
  --bt-primary-test-10: #{$gray-900};
  --bt-primary-test-100: #{$black};
  --bt-primary-test-100: (0, 0, 0);
  --bt-bg: #fdfdfd;
  --bt-bg-10: white;
  --bt-bg-2: #fefefe;
  --cui-input-bg: rgba(0, 0, 0, .04);
  --bt-bg-10-rgb: 240, 240, 245;

  .form-control {
    background-color: $gray-100;
  }

    div.MuiAutocomplete-endAdornment * {
      color: black;
    }

    .sidebar {
      background-color: #{$secondary-25};
    }

    .sidebar * {
      color: #{$gray-900};
    }
}


.sidebar-light {

  button.btn-close, button.btn-close-white {
    color: black;
  }

  .sidebar {
    --cui-sidebar-bg: #efefef !important;
    background-color: #efefef !important;
  }

  a.sidebar-nav-link-item:hover {
    background-color: var(--bt-primary-25);
  }

  .sidebar * {
    color: #{$gray-900} !important;
  }
}