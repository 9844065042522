/* Green Theme */
.green-theme * {
  --bt-disabled-rgb: 200, 255, 200;
  --bt-primary-test: #{$gray-200};
  --bt-primary-test-10: #{$green-200};
  --bt-primary-test-100: #{$green-900};
  --bt-primary-test-100: #{$green-100};
  --bt-bg: #004040;
  --bt-bg-10: #004d4d;
  --bt-bg-2: #003d3d;
  --bt-bg-10-rgb: 0, 37, 37;

  .form-control {
    background-color: #004c4c;
  }

  div.MuiAutocomplete-endAdornment * {
    color: rgb(163, 207, 187);
  }

  .sidebar-green {
    .sidebar {
      --cui-sidebar-bg: var(--bt-bg-10) !important;
    }
  }
}

.sidebar-green {
  .sidebar {
    --cui-sidebar-bg: #003d3d !important;
    background-color: #003d3d !important;
  }

  .sidebar * {
    color: white !important;
  }
}